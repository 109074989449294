import React,{ useContext } from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { Typography,Toast } from "react-vant";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PersistContext } from "@/data/PersistProvider";

const WithdrawControl = () => {
  const { getPersist } = useContext(PersistContext);
  const bgSettings = getPersist("bgSettings");
  let whatsApp = "85253053474";
  if (bgSettings && bgSettings.settings && bgSettings.settings.client_config && bgSettings.settings.client_config.whatsapp) {
    whatsApp = bgSettings.settings.client_config.whatsapp;
  }
  return (
    <div class={style.withdraw_control_container}>
      <HomeHeaderOther title={"Risk control tips"} />
      <div class={style.withdraw_control_top}>
        <p>Your withdrawal request has been submitted. </p>
        <p>Our staff is currently processing it, and it is expected to be completed within 15 minutes to 24 hours.</p>
        <p>We appreciate your patience and understanding during this time.</p>
      </div>
      <div class={style.withdraw_control_intro}>
        Use WhatsApp:{" "}
        <Typography.Text underline style={{ color: "#f1bf3f" }}>
          {whatsApp}
        </Typography.Text>{" "}
                <CopyToClipboard
                  text={whatsApp}
                  onCopy={() => {
                    Toast.success({
                      message: "Copy Successfully",
                    });
                  }}
                >
                  <img
                    style={{ width: "1rem", height: "1rem", margin: "0 .7rem 0 .2rem" }}
                    src={require("@/assets/icons/copy.png").default}
                  />
                </CopyToClipboard>
        to contact customer service
      </div>
      <div class={style.withdraw_control_footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© GameSaka all right reserved</span>
      </div>
    </div>
  );
};

export default WithdrawControl;
