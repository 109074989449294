import React from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { PaymentConfig } from "@/config";
import { LocationInfo } from "@/data/LocationInfo";


const Help = () => {
  const PaymentsText = PaymentConfig.payments.map(payment=>payment.text).join(", ");
  return (
    <div class={style.page_container}>
      <HomeHeaderOther />
      <div class={style.help_content_page}>
        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>How to Create an Account</h2>
          <ul>
            <li>1.Open your web browser and type in:
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >2.When the GameSaka page appears, click on
              <span class={style.help_link_help_2}>“Join”.</span></li>
            <li >3.Enter your number and chosen password of six or more characters then select 	'Create User' to get a PIN via sms.</li>
            <li >4.Enter the PIN and your account will be activated.</li>
          </ul>
          <p class={style.bold}>Note: select a password you will remember because it's the one you will use whenever  you want to visit
            <span class={style.link_help_note}> {LocationInfo().domain}</span>
          </p>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>How to Place a Bet</h2>
          <h2 class={style.help_content_page_h2}>WEB</h2>
          <ul>
            <li>1.Open your web browser and type in:
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >2.When the GameSaka page appears click on login.
            </li>
            <li >3.Enter your number and password to proceed to your account.</li>
            <li >4.Select a game and click play button.</li>
            <li >5.You will see the game main interface.</li>
            <li >6.Enter your desired stake, the amount you wish to bet.</li>
            <li >7.Click on "Place Bet"</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>How to Check My Bets & Account Statement</h2>
          <ul>
            <li>1.Open your web browser and type in:
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >2.When the GameSaka page appears click on login.
            </li>
            <li >3.Enter your number and password to proceed to access your account.</li>
            <li >4.Click 'Profile' bottom menu.</li>
            <li >5.Click on 'My Bets'</li>
            <li >6.When the page appears, you will see the report</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>Forgot Password</h2>
          <ul>
            <li>1.Open your web browser and type in:
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >2.When the GameSaka page appears click on login.
            </li>
            <li >3.Enter your phone number and click on 'forgot password'</li>
            <li >4.You will receive an sms with a reset PIN</li>
            <li >5.Enter the PIN and choose your new password</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>Deposit using {PaymentsText}</h2>
          <div class={style.help_content_page_deposit}>
            Go to the DEPOSIT tab on {LocationInfo().domain} and enter the amount you wish to deposit. 	You will receive a pop up notification on your phone asking you to confirm payment of the amount you intend to denocit to GameKava
          </div>
          <h2 class={style.help_content_page_h2}>OR</h2>
          <ul>
            <li>1.Go to {PaymentsText} Menu on your mobile phone
            </li>
            <li >2.Select Lipa na {PaymentsText}A
            </li>
            <li >3.Enter GameSaka as ACCOUNT NUMBER but you can leave and blank and proceed</li>
            <li >4.Enter the amount to pay (NO COMMAS) e.g 500</li>
            <li >5.Enter your {PaymentsText} PIN and send</li>
            <li >6.You will receive an SMS confirming the transaction</li>
          </ul>
        </div>

        <div class={style.help_content_page_div}>
          <h2 class={style.help_content_page_h2}>How to Request a Withdrawal</h2>
          <ul>
            <li>1.Open your web browser and type in:
              <span class={style.link_help}>{LocationInfo().domain}</span>
            </li>
            <li >2.Select login.
            </li>
            <li >3.Enter your number and password to access your account.</li>
            <li >4.Select 'Withdrawal' bottom menu.</li>
            <li >5.Enter the amount you wish to withdraw (minimum 100/=)</li>
            <li >6.Select 'Request Withdrawal'</li>
          </ul>
          <div class={style.help_content_page_div_last}>
            You may send a withdrawal request at any time; we process withdrawals instantly. The minimum withdrawal amount is 100 Kshs. Please note that additional carrier fees apply 	when processing withdrawal requests to your account. The maximum payout shall be 1,000,000 Kshs on any given ticket.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
