import FirebaseAnalytics from "./FirebaseAnalytics";
import SelfBuildWebcount from "./SelfBuildWebcount";

export const SendEvent = (event, data) => {
  if (!data) {
    data = {};
  }
  //matomo
  if (window._paq && window._paq.push) {
    if (typeof data == "object" && Object.keys(data).length > 0) {
      for (let key in data) {
        window._paq.push(["trackEvent", event, event, key, data[key]]);
      }
    } else {
      window._paq.push(["trackEvent", event, event]);
    }
  }
  //GA
  if (window.gtag) {
    window.gtag("event", event, data);
  }
  //Firebase
  if (FirebaseAnalytics.instance) {
    FirebaseAnalytics.instance.SendEvent(event, data);
  }
  SelfBuildWebcount.GetInstance().SendEventOnce(event, data);
  //JSBridge: AF or Adjust
  if (window.JSBridge && window.JSBridge.SendEvent) {
    window.SendEvent(event, JSON.stringify(data));
  }
};

export const BindUser = (rid) => {
  console.log("BindUser", rid);
  if (window.JSBridge && window.JSBridge.SetRid) {
    console.log("SetRid", rid);
    window.JSBridge.SetRid(rid);
  }
};
