import { useEffect, useRef, useState } from "react";

const Counter = ({ counts, time = 500, startfrom = 0 }) => {
  // 默认500毫秒之内整个动画完成
  const [count, setCount] = useState(0);
  const timer = useRef(null);
  useEffect(() => {
    let startTime = Date.now();
    let duration = 2000;
    //数字增长逻辑：1.定步操作
    // const step = counts <= time ? 1 : Math.ceil(counts / time);  // 两种情况：1.总数小于time时，就以每毫秒递增1的形式增加；2.总数达于1000，计算出每毫秒至少要递增多少
    if (timer.current != null) {
      clearInterval(timer.current);
      timer.current = null;
    }
    timer.current = setInterval(() => {
      setCount((pre) => {
        // let after = pre + step;
        //数字增长逻辑：2.定时操作
        let after = startfrom + Math.ceil(((Date.now() - startTime) / duration) * (counts - startfrom) * 100) / 100;
        if (after > counts) {
          clearInterval(timer.current);
          after = counts;
        }
        if (typeof after === "number") {
          after = after.toFixed(2);
        } else if (typeof after === "string") {
          after = parseFloat(after).toFixed(2);
        }
        return after;
      });
    }, 16);
    return () => clearInterval(timer.current);
  }, [counts, startfrom]);
  return count;
};
export default Counter;
