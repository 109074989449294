import React from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { LocalConfig } from "@/config";

const About = () => {
  return (
    <div class={style.about_container}>
      <HomeHeaderOther title={"About Us"} />
      <div class={style.about_content}>
          <div class={style.about_content_title}>
              <img src={require("@/assets/icon.png").default} style={{ width: "2.6rem", height: "2.6rem"}} />
              <img src={require("@/assets/images/header/fz_char_logo_white.png").default} style={{ width: "7.9rem", marginLeft: " 0.555rem", marginTop: "0.3rem" }} />
          </div>
          <div class={style.about_content_intro}>
            <span>SAKA GAME LIMITED is licensed by: BCLB under the Betting, Lotteries and Gaming Act. Laws of {LocationInfo().name} under license </span><br />
              <div class={style.about_content_no}>{LocalConfig.licence}</div>
          </div>
      </div>
        <div class={style.footer_common}>
            <img src={require("@/assets/images/home/18_plus.png").default} />
            <span><span class={style.symbol}>©</span> GameSaka all right reserved</span>
        </div>
    </div>
  );
};

export default About;
