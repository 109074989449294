import axios from "axios";
import { GetHallServerUrl } from "@/data/urls";

export const SendCMD = async (cmd, msg) => {
  let result = await axios({
    method: "post",
    url: GetHallServerUrl(),
    data: JSON.stringify({
      cmd,
      msg,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
  return result.data;
};
