import { createContext, useState } from "react";

const defaultRootState = {
  sidebarOpened: false,
  depositPromotionOpenTime: -1,
  invitePromotionOpenDay: -1,
};

// 创建一个 Context
export const RootStateContext = createContext(defaultRootState);

// 创建一个 DataProvider 组件
export const RootStateProvider = ({ children }) => {
  const [rootState, setRootState] = useState(defaultRootState);

  const setRootStateKey = (key, value) => {
    let data = { ...rootState };
    data[key] = value;
    setRootState(data);
  };

  return (
    <RootStateContext.Provider value={{ rootState, setRootState, setRootStateKey }}>
      {children}
    </RootStateContext.Provider>
  );
};
