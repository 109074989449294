import { useContext, useEffect, useRef } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import DebugDiv from "./components/DebugDiv";
import { PersistContext } from "./data/PersistProvider";
import DownloadApp from "./routes/DownloadApp";
import Game from "./routes/Game";
import Index from "./routes/Index";
import About from "./routes/Index/About";
import Contact from "./routes/Index/Contact";
import DepositLog from "./routes/Index/DepositLog";
import Help from "./routes/Index/Help";
import Privacy from "./routes/Index/Privacy";
import Respgame from "./routes/Index/Respgame";
import Termcond from "./routes/Index/Termcond";
import WithdrawControl from "./routes/Index/WithdrawControl";
import WithdrawLog from "./routes/Index/WithdrawLog";
import InviteWheel from "./routes/InviteWheel";
import Login from "./routes/Login";
import SendRegCode from "./routes/Login/SendRegCode";
import LuckyDraw from "./routes/LuckyDraw";
import Setting from "./routes/Setting";
import ChangePwd from "./routes/Setting/ChangePwd";
import Forgot from "./routes/Setting/Forgot";
import SetNewPwd from "./routes/Setting/SetNewPwd";
import Verify from "./routes/Setting/Verify";
import TaskCenter from "./routes/TaskCenter";
import { SendCMD } from "./utils/HTTPRequest";
import HarvestBreadfruit from "./routes/HarvestBreadfruit";
import WreathOfThorns from "./routes/WreathOfThorns";

// Translated messages in French with matching IDs to what you declared
const messages = {
  myMessage: "Aujourd'hui, c'est le {ts, date, ::yyyyMMdd}",
};

const locationTitles = {
  "/home": "Game Saka",
};

/**
 * 映射location对应的title
 * @param {window.location} location
 */
const MapLocationTitles = (location) => {
  let title = "Game Saka";
  if (typeof locationTitles[location.pathname] !== "undefined") {
    title = locationTitles[location.pathname];
  } else if (location.pathname.startsWith("/c/")) {
    title = "Game Saka";
  } else {
    title = `${location.pathname.substring(1, 2).toUpperCase()}${location.pathname.substring(2)}`;
    // reaplace "/" to " "
    title = title.replace(/\//g, " ");
    if (title.trim() === "") {
      title = "Game Saka";
    } else {
      title = `${title} - Game Saka`;
    }
  }
  //登录页面，如果reg=1，显示注册
  if (location.pathname === "/login") {
    let query = new URLSearchParams(location.search);
    let reg = query.get("reg");
    if (reg) {
      title = "Register - Game Saka";
    }
  }
  return title;
};

const App = () => {
  const location = useLocation();
  const { getPersist, setPersist } = useContext(PersistContext);
  const bgLoaderTimer = useRef();
  const bgSettings = getPersist("bgSettings");
  async function loadBgSettings() {
    let query = new URLSearchParams(location.search);
    let channel = query.get("c");
    if (!channel && location.pathname.startsWith("/c/")) {
      channel = location.pathname.substring(3);
    }
    if (getPersist("channel")) {
      channel = getPersist("channel");
    } else if (channel) {
      setPersist("channel", channel);
    }
    //定时关闭hallloader，避免因为bgSettings接口的问题导致一直显示loader
    if (bgLoaderTimer.current) {
      clearTimeout(bgLoaderTimer.current);
      bgLoaderTimer.current = null;
    }
    bgLoaderTimer.current = setTimeout(() => {
      closeHallLoader();
    }, 3000);
    let guest_id = window.guestId ? window.guestId() : "";
    let res = await SendCMD("bgSettings", {
      channel,
      guest_id,
      first_visit_url: localStorage.getItem("first_visit_url"),
      first_visit_time: localStorage.getItem("first_visit_time"),
    });
    console.log("bgSettings res", res);
    if (res && res.settings && res.settings.channel_info && res.settings.channel_info.fb_id) {
      console.log("fb_pixel_id", res.settings.channel_info.fb_id);
      setPersist("fb_pixel_id", res.settings.channel_info.fb_id);
    }
    if (res) {
      setPersist("bgSettings", res);
      if (res.abtest_tag) {
        setPersist("abtest_tag", res.abtest_tag);
      }
      if (res.client_show_time) {
        setPersist("client_show_time", res.client_show_time);
      }
      if (res.recall_act_open) {
        setPersist("recall_act_open", res.recall_act_open);
      }
    }
    closeHallLoader();
  }

  //关闭loader
  const closeHallLoader = () => {
    let loader = document.getElementById("hall_loader");
    if (loader) {
      loader.style.display = "none";
      loader.parentNode.removeChild(loader);
    }
  };

  useEffect(() => {
    if (!bgSettings) {
      loadBgSettings()
        .then(() => {})
        .catch(() => {});
    }
  }, []);
  useEffect(() => {
    let fbPixelId = getPersist("fb_pixel_id");
    console.log("fbPixelId", fbPixelId);
    if (fbPixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      fbq("init", fbPixelId);
      fbq("track", "PageView");
      let watchTimes = 0;
      let watchFbpID = setInterval(() => {
        if (++watchTimes > 100) {
          clearInterval(watchFbpID);
        }
        let fbpID = "";
        var value = "; " + document.cookie;
        var parts = value.split("; _fbp=");
        if (parts.length == 2) {
          fbpID = parts.pop().split(";").shift();
          if (fbpID) {
            localStorage.setItem("attribution", "fb");
            localStorage.setItem("attribution_id", fbpID);
            localStorage.setItem("ad_source", fbPixelId);
            clearInterval(watchFbpID);
          }
        } else {
          console.error("cookie", document.cookie);
        }
      }, 300);
    }
  }, [getPersist("fb_pixel_id")]);

  useEffect(() => {
    console.log("location", location);
    let title = MapLocationTitles(location);
    if (document.title != title) {
      document.title = title;
    }
  }, [location]);
  return (
      <div id="app">
        <Switch>
          <Route exact path="/home">
            <Index />
          </Route>
          <Route exact path="/px">
            <Index />
          </Route>
          <Route exact path="/c">
            <Index />
          </Route>
          <Route exact path="/deposit">
            <Index />
          </Route>
          <Route exact path="/promotion">
            <Index />
          </Route>
          <Route exact path="/withdraw">
            <Index />
          </Route>
          <Route exact path="/profile">
            <Index />
          </Route>
          <Route exact path="/invite">
            <Index />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/sendRegCode">
            <SendRegCode />
          </Route>
          <Route exact path="/game">
            <Game />
          </Route>
          <Route exact path="/help">
            <Help />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/Termcond">
            <Termcond />
          </Route>
          <Route exact path="/Privacy">
            <Privacy />
          </Route>
          <Route exact path="/Respgame">
            <Respgame />
          </Route>
          <Route exact path="/depositLog">
            <DepositLog />
          </Route>
          <Route exact path="/withdrawLog">
            <WithdrawLog />
          </Route>
          <Route exact path="/setting">
            <Setting />
          </Route>
          <Route exact path="/changePwd">
            <ChangePwd />
          </Route>
          <Route exact path="/forgot">
            <Forgot />
          </Route>
          <Route exact path="/verify">
            <Verify />
          </Route>
          <Route exact path="/setNewPwd">
            <SetNewPwd />
          </Route>
          <Route exact path="/withdrawControl">
            <WithdrawControl />
          </Route>
          <Route exact path="/downloadApp">
            <DownloadApp />
          </Route>
          <Route exact path="/luckyDraw">
            <LuckyDraw />
          </Route>
          <Route exact path="/taskCenter">
            <TaskCenter />
          </Route>
          <Route exact path="/InviteWheel">
            <InviteWheel />
          </Route>
          <Route exact path="/harvest">
            <HarvestBreadfruit />
          </Route>
          <Route exact path="/wreath">
            <WreathOfThorns />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>
        <DebugDiv />
      </div>
  );
};

export default App;
