import React from "react";
import Url from "url-parse";
import style from "./style.less";
import { GetHallServerUrl } from "@/data/urls";

const DebugDiv = () => {
  let urlParsed = new Url(window.location.href, true);
  let hallUrl = localStorage.getItem("hall_url");
  if (!hallUrl) {
    hallUrl = GetHallServerUrl();
  }
  let [serverUrl, setServerUrl] = React.useState(hallUrl);
  React.useEffect(() => {
    setServerUrl(hallUrl);
  }, [hallUrl]);
  const setHostPort = () => {
    localStorage.setItem("hall_url", serverUrl);
  };
  if (
    urlParsed.query &&
    urlParsed.query["debug"] &&
    urlParsed.query["debug"] == "true"
  ) {
    return (
      <div class={style.container}>
        <div class={style.field}>
          <span>Login Server:</span>
          &nbsp;
          <input
            value={serverUrl}
            onChange={(e) => {
              setServerUrl(e.target.value);
            }}
          />
          &nbsp;
          <button onClick={setHostPort}>保存</button>
        </div>
      </div>
    );
  }
  return null;
};

export default DebugDiv;
