import React from "react";
import style from "./style.less";

const UserLogShow = (props) => {
    return (
        <div class={`${style.info} ${props.k === 9 || props.k + 1 ===  props.length? style.end_i : ""}`} >
             <div class={`${style.info_time} ${props.k === 0 ? style.first_i : ""}`} >{props.info.time}</div>
             <div class={`${style.info_amount} ${props.k === 0 ? style.first_i : ""}`}>{props.info.amount}</div>
             <div class={`${style.info_status} ${props.k === 0 ? style.first_i : ""}`}>{props.info.status}</div>
        </div>
    );
};

export default UserLogShow;
