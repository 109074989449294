import React from "react";
import style from "./style.less";
import HomeHeaderOther from "@/components/HomeHeader/indexOther";

const Respgame = () => {
  return (
    <div class={style.page_container}>
      <HomeHeaderOther />
      <div class={style.help_content}>
        <div  class={style.pri_intro}>
          GameSaka is committed to Responsible Gambling.
          We promote gambling as an enjoyable leisure activity and we believe that gambling can only remain this way if you stay in control and gamble responsibly.
          However, we know that for some people gambling can stop being a harmless leisure activity and become a problem. To assist you we offer a range of advice and account options to help you manage your gambling. These give you control of both the time and money you spend gambling.
        </div>
        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>Advice on Staying in Control</h2>
          <ul>
            <li>
              We believe that gambling should always be an enjoyable leisure activity. Remembering these simple tips can help make sure your gambling doesn't become a problem.
              <p>1. Gambling should be entertaining and not seen as a way of making money.</p>
              <p>2. Bet sensibly and never chase your losses.</p>
              <p>3. Only gamble what you can afford to lose. Use Deposit Limits to manage the amount of 	money you spend.</p>
              <p>4. Monitor the amount of time you spend playing. Set up Reality Checks to remind you how 	long you have been logged into your account.</p>
              <p>5. Balance gambling with other activities. If gambling is your only form of entertainment 	think about whether you are still having fun.6. Take regular breaks from gambling. Gambling 	continuously can cause you to lose track of time and perspective.</p>
              <p>7. Don't gamble when under the influence of alcohol or when you're upset or depressed.</p>
              <p>8. Think about how much money you spend gambling. You can track your activity in your 	Online Account History.</p>
              <p>9. If you need to talk to someone about problem gambling then Contact our customer 	service team or a problem gambling support service.</p>
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>Deposit Limits</h2>
          <ul>
            <li>
              We encourage you to set a deposit limit to manage the amount of money that you can deposit into your account. Limits can be set for a 24 hour, 7 day or 30 day period and cannot be overridden.
              You can decrease your deposit limits at any time and this will be applied immediately.
              Before increasing your deposit limits carefully consider if you can afford to do so. Never decide to increase your limit because you have lost money and think that you will win it back by gambling more. If you wish to increase a limit you will need to wait 24 hours before we action your request and then return to our site to confirm that you still want to increase it.
              You can set, amend and confirm your deposit limits in the Responsible Gambling Controls section of Members by choosing Deposit Limits.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>Online Account History</h2>
          <ul>
            <li>
              To enable you to keep track of your activity, you can access a history of your transactions, deposits and withdrawals in our My Account area and your balance is always visible in the top left corner of the page when logged in to GameSaka.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>Self-Exclusion</h2>
          <ul>
            <li>
              If you feel you are at risk of developing a gambling problem or believe you currently have a gambling problem, please consider Self-Exclusion. If you want to stop playing for any other reason, please consider taking a Time-Out or using Account Closure.
              Self-Exclusion allows you to close your account for a specified period of 6 months, 1 year, 2 years or 5 years. This prevents you gambling with GameSaka for your selected period of time.
              During a period of self-exclusion you will not be able to use your account for betting and gaming, although you will still be able to login to withdraw any remaining balance. It will not be possible to re-open your account for any reason, and GameSaka will do all it can to detect and close any new accounts you may open.
              You also have the option of selecting which areas of the site you would like to limit your access to. For example you could limit your access to our Casino or any other products.
              Should you opt to self-exclude from GameSaka, we strongly recommend that you seek exclusion from all other gambling operators you have an account with. Whilst we will remove you from our marketing databases, we also suggest that you remove GameSaka from your notifications and delete/uninstall all GameSaka apps, downloads and social media links. You may also wish to consider installing software that blocks access to gambling websites.
              We recommend that you seek support from a problem gambling support service to help you deal with your problem.
              Our customer service staff receive dedicated training on dealing with problem gambling issues and are available to listen and to support you in keeping control. If you would like to talk to someone here about problem gambling please
              Contact Us.
            </li>
          </ul>
        </div>

        <div class={style.help_content_div}>
          <h2 class={style.help_content_h2}>Protecting Minors</h2>
          <ul>
            <li>
              It is against the law for those under the age of 18 to gamble and GameSaka takes its responsibility to prevent underage gambling very seriously.
              We carry out age verification checks on all customers and ask for documentary proof of age where checks are inconclusive.
              The accounts of anyone under the age of 18 found to be gambling with GameSaka will be closed and any winnings will be forfeited.
              If you suspect that someone under the age of 18 is gambling with GameSaka please Contact Us to report this.
            </li>
          </ul>
        </div>
        <div class={style.help_content_div_last}></div>
      </div>
    </div>
  );
};

export default Respgame;
