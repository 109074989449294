import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import MyNavLink from "@/components/MyNavLink";
import { PersistContext } from "@/data/PersistProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect, useState } from "react";
import { Form, Toast } from "react-vant";
import style from "../Setting/style.less";

const ChangePwd = () => {
  const { getPersist } = React.useContext(PersistContext);
  const history = useEnhancedHistory();

  useEffect(() => {
    if (!getPersist("dataLoaded")) {
      return;
    }
    if (!getPersist("user_info")) {
      history.push(ChannelRemind("/login"));
      return;
    }
  }, [getPersist("dataLoaded")]);

  const [form] = Form.useForm();

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [formValidated, setFormValidated] = useState(false);

  const onSubmit = () => {
    if (!formValidated) {
      return;
    }
    if (!oldPassword || !newPassword) {
      Toast.info("The input field cannot be empty");
      return;
    }

    if (oldPassword.length < 6 || oldPassword.length > 16) {
      Toast.info("The old password contains 6 to 16 characters");
      return;
    }

    if (newPassword.length < 6 || newPassword.length > 16) {
      Toast.info("The new password contains 6 to 16 characters");
      return;
    }

    //修改密码请求
    SendCMD("changePasswd", {
      old_passwd: oldPassword,
      new_passwd: newPassword,
      token: getPersist("user_info").token,
    }).then((res) => {
      if (!res.status) {
        Toast.info(res.errdesc);
      } else {
        Toast.info("Password changed successfully");
        history.replace(ChannelRemind("/login"));
      }
    });
  };

  useEffect(() => {
    if (newPassword && oldPassword) {
      setFormValidated(() => true);
    } else {
      setFormValidated(() => false);
    }
  }, [newPassword, oldPassword]);

  return (
    <div class={style.page_container_change_pwd}>
      <HomeHeaderOther title={"Change Password"} />
      <div class={style.change_pwd_top}>Change Password</div>
      <div class={style.change_pwd_form}>
        <div>
          <div class={style.change_pwd_input_container}>
            <input
              placeholder="Old password"
              type={showPassword1 ? "text" : "password"}
              onChange={(e) => {
                let value = e.target.value;
                let regex = /^[a-zA-Z0-9_-]{6,16}$/;
                if (regex.test(value)) {
                  setOldPassword(value);
                } else {
                  setOldPassword("");
                }
              }}
            />
            <img
              class={style.change_pwd_eye}
              src={`../assets/images/home/icon_eye${showPassword1 ? "_close" : ""}.png`}
              onClick={() => {
                setShowPassword1(!showPassword1);
              }}
            />
          </div>
          <div class={style.change_pwd_input_container}>
            <input
              placeholder="New password"
              type={showPassword2 ? "text" : "password"}
              onChange={(e) => {
                let value = e.target.value;
                let regex = /^[a-zA-Z0-9_-]{6,16}$/;
                if (regex.test(value)) {
                  setNewPassword(value);
                } else {
                  setNewPassword("");
                }
              }}
            />
            <img
              class={style.change_pwd_eye}
              src={`../assets/images/home/icon_eye${showPassword2 ? "_close" : ""}.png`}
              onClick={() => {
                setShowPassword2(!showPassword2);
              }}
            />
          </div>
          <div style={{ margin: "1rem 1rem 0 1rem" }}>
            <div
              class={style.change_pwd_submit_btn + (!formValidated ? ` ${style.change_pwd_submit_btn_disabled}` : "")}
              onClick={onSubmit}
            >
              Change
            </div>
          </div>
        </div>
      </div>
      <div class={style.change_pwd_forget}>
        <MyNavLink to="/forgot">
          <span>Forgot Password?</span>
        </MyNavLink>
      </div>
      <div class={style.footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© GameSaka all right reserved</span>
      </div>
    </div>
  );
};

export default ChangePwd;
