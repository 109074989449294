import App from "./App";
import { PersistProvider } from "./data/PersistProvider";
import { RootStateProvider } from "./data/RootStateProvider";
import { RouterProvider } from "./data/RouterProvider";
import "./style";
import "./style/iconfont.css";
import FirebaseAnalytics from "./utils/FirebaseAnalytics";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

function resizeFontsize() {
  let w = document.getElementById("app").offsetWidth;
  let _w = window.screen.width;
  console.log("resizeFontsize", w, _w, window.rem);
  if (w > _w && _w < window.rem * 23.8) {
    document.documentElement.style.fontSize = `${(window.rem * _w) / w}px`;
  }
}
if (window) {
  !(function (win) {
    function resize() {
      var domWidth = domEle.getBoundingClientRect().width;
      if (domWidth / v > 540) {
        domWidth = 540 * v;
      }
      win.rem = domWidth / 23.75;
      console.log("resize", domWidth, win.rem);
      domEle.style.fontSize = win.rem + "px";
      try {
        resizeFontsize();
      } catch (e) {}
    }
    var v,
      initial_scale,
      timeCode,
      dom = win.document,
      domEle = dom.documentElement,
      viewport = dom.querySelector('meta[name="viewport"]'),
      flexible = dom.querySelector('meta[name="flexible"]');
    if (viewport) {
      //viewport：<meta name="viewport"content="initial-scale=0.5, minimum-scale=0.5, maximum-scale=0.5,user-scalable=no,minimal-ui"/>
      var o = viewport.getAttribute("content").match(/initial\-scale=(["']?)([\d\.]+)\1?/);
      if (o) {
        initial_scale = parseFloat(o[2]);
        v = parseInt(1 / initial_scale);
      }
    } else {
      if (flexible) {
        var o = flexible.getAttribute("content").match(/initial\-dpr=(["']?)([\d\.]+)\1?/);
        if (o) {
          v = parseFloat(o[2]);
          initial_scale = parseFloat((1 / v).toFixed(2));
        }
      }
    }
    if (!v && !initial_scale) {
      var n = (win.navigator.appVersion.match(/android/gi), win.navigator.appVersion.match(/iphone/gi));
      v = win.devicePixelRatio;
      (v = n ? (v >= 3 ? 3 : v >= 2 ? 2 : 1) : 1), (initial_scale = 1 / v);
    }
    //没有viewport标签的情况下
    if ((domEle.setAttribute("data-dpr", v), !viewport)) {
      if (
        ((viewport = dom.createElement("meta")),
        viewport.setAttribute("name", "viewport"),
        viewport.setAttribute(
          "content",
          "initial-scale=" +
            initial_scale +
            ", maximum-scale=" +
            initial_scale +
            ", minimum-scale=" +
            initial_scale +
            ", user-scalable=no"
        ),
        domEle.firstElementChild)
      ) {
        domEle.firstElementChild.appendChild(viewport);
      } else {
        var m = dom.createElement("div");
        m.appendChild(viewport), dom.write(m.innerHTML);
      }
    }
    win.dpr = v;
    win.addEventListener(
      "resize",
      function () {
        clearTimeout(timeCode), (timeCode = setTimeout(resize, 300));
      },
      false
    );
    win.addEventListener(
      "pageshow",
      function (b) {
        b.persisted && (clearTimeout(timeCode), (timeCode = setTimeout(resize, 300)));
      },
      false
    );
    resize();
  })(window);
}

if (window) {
  if (typeof window.setAttribution != "function") {
    window.setAttribution = function (attributionInfo) {
      if (!localStorage.getItem("attribution") && attributionInfo.id && attributionInfo.name) {
        localStorage.setItem("attribution", attributionInfo.name);
        localStorage.setItem("attribution_id", attributionInfo.id);
        localStorage.setItem("ad_source", attributionInfo.source);
      }
      console.log("setAttribution", attributionInfo);
      return attributionInfo;
    };
  }
  if (typeof window.setAFID != "function") {
    window.setAFID = function (AFID) {
      if (!localStorage.getItem("attribution")) {
        localStorage.setItem("attribution", "af");
        localStorage.setItem("attribution_id", AFID);
      }
      console.log("setAFID", AFID);
      return AFID;
    };
    window.setAppsflyerId = window.setAFID;
  }
  if (typeof window.setAdSource != "function") {
    window.setAdSource = function (adSource) {
      if (!localStorage.getItem("ad_source")) {
        localStorage.setItem("ad_source", adSource);
      }
      console.log("setAdSource", adSource);
      return adSource;
    };
    window.setMediaSource = window.setAdSource;
  }
  if (window.SakaBridge) {
    window.JSBridge = window.SakaBridge;
  }
  if (window.JSInterface) {
    window.JSBridge = window.JSInterface;
    window.JSBridge.SetRid = window.JSInterface.SetUserId;
  }
  if (window.JSBridge && window.JSBridge.SendEvent) {
    window.JSBridge.SendEvent("page_view", JSON.stringify({ page: "home" }));
  }
}
if (process.env.PREACT_APP_ENV_CONFIG.firebaseConfig) {
  FirebaseAnalytics.GetInstance(process.env.PREACT_APP_ENV_CONFIG.firebaseConfig);
}
const Main = () => {
  return (
    <PersistProvider>
      <RootStateProvider>
        <RouterProvider>
          <App />
        </RouterProvider>
      </RootStateProvider>
    </PersistProvider>
  );
};

export default Main;
