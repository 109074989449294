import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics, logEvent } from "firebase/analytics";
export default class FirebaseAnalytics {
  constructor(firebaseConfig) {
    if (FirebaseAnalytics.instance) {
      return FirebaseAnalytics.instance;
    }
    this.firebaseConfig = firebaseConfig;
    this.fireBaseApp = initializeApp(this.firebaseConfig);
    // Initialize Performance Monitoring and get a reference to the service
    this.perf = getPerformance(this.fireBaseApp);
    /**
     * @type {@firebase/analytics/Analytics}
     */
    this.fireBaseAnalytics = getAnalytics(this.fireBaseApp);
    FirebaseAnalytics.instance = this;
  }
  SendEvent(event, data) {
    logEvent(this.fireBaseAnalytics, event, data);
  }
}
FirebaseAnalytics.GetInstance = (firebaseConfig) => {
  if (FirebaseAnalytics.instance) {
    return FirebaseAnalytics.instance;
  }
  return new FirebaseAnalytics(firebaseConfig);
};
