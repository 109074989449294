export function ChannelRemind(url) {
  let channel = "";
  if (location.href.includes("c=")) {
    channel = location.href.split("c=")[1].split("&")[0];
  }
  if (channel == "") {
    return url;
  }
  let urlHasParams = url.includes("?");
  if (urlHasParams) {
    if (!url.includes("c=")) {
      // add c in url
      url = `${url}&c=${channel}`;
    }
  } else {
    url = `${url}?c=${channel}`;
  }
  return url;
}

export function getQuery(key) {
  const query = new URLSearchParams(window.location.search);
  let value = query.get(key);
  console.log("getQuery", key, value);
  return value;
}
