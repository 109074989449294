import {LocalConfig} from "@/config";
export const LocationDefine = {
  KE: {
    name: "Kenya",
    code: "KE",
    currency: "KES",
    currencyName: "Kenyan Shilling",
    phoneStartsWith: "254",
    lang: "en",
    flag: require("@/assets/images/header/flag_kenya.png").default,
    domain: "gamesaka.com",
    url: "https://gamesaka.com",
  },
  UG: {
    name: "Uganda",
    code: "UG",
    currency: "UGX",
    currencyName: "Ugandan Shilling",
    phoneStartsWith: "256",
    lang: "en",
    flag: require("@/assets/images/header/flag_uganda.png").default,
    dimain: "sakaug.com",
    url: "https://sakaug.com",
  },
  GH: {
    name: "Ghana",
    code: "GH",
    currency: "GHS",
    currencyName: "Ghanaian Cedi",
    phoneStartsWith: "233",
    lang: "en",
    flag: require("@/assets/images/header/flag_ghana.png").default,
    dimain: "sakagh.com",
    url: "https://sakagh.com",
  },
};

/**
 *
 * @param {string | null} locationKey
 * @returns {name, code, currency, currencyName, phoneStartsWith, lang, flag, domain, url}
 */
export function LocationInfo(locationKey) {
  if (!locationKey) {
    locationKey = LocalConfig.locationKey;
  }
  let upperLocationKey = locationKey.toUpperCase();
  if (!LocationDefine[upperLocationKey]) {
    upperLocationKey = "KE";
  }
  return LocationDefine[upperLocationKey];
}
