/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 6000, price: 6000 },
    { get: 10000, price: 9900 },
    { get: 50000, price: 49500, hot: 1 },
    { get: 100000, price: 99000, hot: 1 },
    { get: 250000, price: 247500, hot: 1 },
    { get: 500000, price: 495000, hot: 1 },
    { get: 1000000, price: 990000, hot: 1 },
    { get: 2000000, price: 2000000, hot: 1 },
    { get: 5000000, price: 5000000 },
  ],
  firstRechargeAmount: 6000,
  firstRechargedWithdrawNeedBets: 30000,
  depositActivityAmount: 15000,
  depositActivityMore: 3000,
  defaultDepositGot: 180,
  defaultDepositPrice: 150,
  minDepositAmount: 6000,
  maxDepositAmount: 10000000,
  minWithdrawAmount: 10000,
  maxWithdrawAmount: 2000000,
  maxWithdrawDailyAmount: 30000000,
  inviteWheel: {
    targetAmount: 50000,
  },
  luckyDraw: {
    marqueeAmoundList: [20, 50, 80, 100, 500, 250, 80, 20, 50, 50, 20, 20],
  },
  addHomeScreenRewardFrom: 5,
  addHomeScreenRewardTo: 1000,
  gasBurnRatio: 10,
  betGasRatio: 1,
  depositGasRatio: 1,
};
