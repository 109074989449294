/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
    locationKey: "KE",
    currencyCode: "KES",
    currencyName: "Kenyan Shilling",
    licence: "No.0000356",
    indexBannerActivityImg : require("@/assets/images/home/index_banner_activity.png").default,
    indexBannerDepositImg: require("@/assets/images/home/index_banner_deposit_ke.png").default,
    indexBannerDeposit2Img: require("@/assets/images/home/index_banner_deposit2.png").default,
    indexBannerDrawImg: require("@/assets/images/home/index_banner_draw.png").default,
    indexBannerinviteImg: require("@/assets/images/home/index_banner_invite_ke.png").default,
    indexBannerLuckyWheelImg: require("@/assets/images/home/index_banner_lucky_wheel_ke.png").default,
    indexBannerInstallImg: require("@/assets/images/home/index_banner_install_ke.png").default,
    downloadTipTextImg: require("@/assets/images/download/top_zi_ke.png").default,
    luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2_ke.png",
}