//格式化一个数字到千位分隔符,保留fixed位小数,如果不传fixed,则不做小数位处理。同时传入units：["K","M","B"]，则表示允许的单位，如果不传，则默认为["K","M","B"]
export const FormatNumberToKMB = (num, units, fiexed) => {
  if (typeof num !== "number") {
    return num;
  }
  if (typeof units === "undefined") {
    units = ["K", "M", "B"];
  }
  let unit = "";
  let result = num;
  for (let i = 0; i < units.length; i++) {
    if (result < 1000) {
      break;
    }
    result = result / 1000;
    unit = units[i];
  }
  if (!fiexed) {
    return result + unit;
  }
  return result.toFixed(fiexed) + unit;
};

export const SinNumberGenerator = (x, offset) => {
  return (Math.sin(x + offset) + 1) / 2;
};

export function DeepEqual(obj1, obj2) {
  // If both are the exact same object or both are null/undefined, return true
  if (obj1 === obj2) return true;

  // If one is null or undefined but the other isn't, return false
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
  }

  // Get the keys from both objects
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  // If they have a different number of keys, they're not the same
  if (keys1.length !== keys2.length) return false;

  // Check if the keys from the first object exist in the second object
  // and their associated values are the same
  for (let key of keys1) {
      if (!keys2.includes(key) || !DeepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  // If all tests pass, return true
  return true;
}

export function GetOS() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/Win(dows )?NT/.test(userAgent)) {
    return "Windows";
  }
  if (/macintosh|mac os x/i.test(userAgent)) {
    return "Mac";
  }
  return "Unknown";
}

export function GetBrowser() {
  let userAgent = navigator.userAgent;

  if (/Opera|OPR\//.test(userAgent)) {
    return "Opera";
  } else if (/Edge\/\d./i.test(userAgent)) {
    return "Edge";
  } else if (/Trident/.test(userAgent)) {
    return "Internet Explorer";
  } else if (/Chrome/.test(userAgent)) {
    return "Chrome";
  } else if (/Firefox/.test(userAgent)) {
    return "Firefox";
  } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    return "Safari";
  }
  return "Unknown";
}
