import { createContext, useContext, useState } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";

// 创建一个 Context 来存储和共享 'from' 变量
const EnhancedHistoryContext = createContext(null);

export const useEnhancedHistory = () => {
  return useContext(EnhancedHistoryContext);
};

const EnhancedHistoryProvider = ({ children }) => {
  const history = useHistory();
  const [from, setFrom] = useState(null);

  const enhancedGoBack = () => {
    setFrom(new URL(window.location.href));
    history.goBack();
  };

  const enhancedPush = (path, state) => {
    setFrom(new URL(window.location.href));
    history.push(path, state);
  };

  const enhancedReplace = (path, state) => {
    setFrom(new URL(window.location.href));
    history.replace(path, state);
  };

  return (
    <EnhancedHistoryContext.Provider
      value={{ ...history, push: enhancedPush, replace: enhancedReplace, goBack: enhancedGoBack, from }}
    >
      {children}
    </EnhancedHistoryContext.Provider>
  );
};

export const RouterProvider = ({ children }) => {
  return (
    <BrowserRouter>
      <EnhancedHistoryProvider>{children}</EnhancedHistoryProvider>
    </BrowserRouter>
  );
};
