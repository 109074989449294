import axios from "axios";
import { GetWebcountServerUrl } from "@/data/urls";


export default class SelfBuildWebcount {
  constructor() {
    this.sentEventMap={};
    //从localStorage中获取sentEventMap
    let sentEventMapStr = localStorage.getItem("sentEventMap");
    if (sentEventMapStr) {
      this.sentEventMap = JSON.parse(sentEventMapStr);
    }
  }
  async SendEventOnce(event, data){
    if(this.sentEventMap[event]){
      return;
    }
    this.sentEventMap[event] = 1;
    localStorage.setItem("sentEventMap", JSON.stringify(this.sentEventMap));
    data.op = "once";
    return await this.SendEvent(event, data);
  }
  async SendEvent(event, data){
    //parse persist data
    let persistDataStr = localStorage.getItem("persist");
    let persistData = {};
    try{
      persistData = JSON.parse(persistDataStr);
    }catch(e){
      console.error(e);
    }
    persistData = persistData || {};
    //set abtest_tag
    if (persistData && persistData.abtest_tag) {
      data.abtest_tag = persistData.abtest_tag;
    }
    //set channel
    if (persistData && persistData.channel) {
      data.channel = persistData.channel;
    }
    data.guest_id = window.guestId ? window.guestId() : "";
    data.event = event;
    let result = await axios({
      method: "get",
      url: GetWebcountServerUrl(),
      params: { event, guest_id: window.guestId ? window.guestId() : "", ...data },
    });
    return result.data;
  }
}

/**
 * 
 * @returns {SelfBuildWebcount}
 */
SelfBuildWebcount.GetInstance = () => {
  if (SelfBuildWebcount.instance) {
    return SelfBuildWebcount.instance;
  }
  return new SelfBuildWebcount();
};
